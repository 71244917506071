h3{
    text-align: center;
}
.main-container{
    padding: 0 2rem;
    
}
div.main-container span{
    text-align: center;
    /* text-transform: capitalize; */
}
div.ble-tab-content strong{
    text-align: center;
    /* text-transform: uppercase; */
}
