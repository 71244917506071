button,
input,
optgroup,
select,
textarea {
  font-family: $sans-serif-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

a {
  color: $primary-color;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

h1,
.h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: 0.8;
  }
}

h2,
.h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}

h3,
.h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}

h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;

  &+.category,
  &.title+.category {
    margin-top: -10px;
  }
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

p {
  &.description {
    font-size: 1.14em;
  }
}

// i.fa{
//     font-size: 18px;
//     position: relative;
//     top: 1px;
// }

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }

  &+.category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: $dark-gray;
  font-weight: $font-weight-light;
}

.category,
.card-category {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  font-size: $font-size-mini;
}

.card-category {
  font-size: $font-size-h6;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: $brand-primary !important;
}

.text-info,
a.text-info:focus,
a.text-info:hover {
  color: $brand-info !important;
}

.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $brand-success !important;
}

.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $brand-warning !important;
}

.text-danger,
a.text-danger:focus,
a.text-danger:hover {
  color: $brand-danger !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
  color: $light-gray !important;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;

  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &.blockquote-primary {
    border-color: $primary-color;
    color: $primary-color;

    small {
      color: $primary-color;
    }
  }

  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;

    small {
      color: $danger-color;
    }
  }

  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;

    small {
      color: $opacity-8;
    }
  }
}

.hero-cards {
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.card-head {
  text-align: center;
  font-weight: bolder;
}

.card-foot {
  text-align: center;
  padding-bottom: 15px;
  font-weight: bolder;
  color: rgb(48, 66, 140);
  font-size: 20px;
}

.current-card {
  font-weight: bold;
  color: rgb(48, 66, 140);
  font-size: 15px;
}

.support-cards {
  width: 100%;
  text-align: center;
}

.padding-15px {
  padding-top: 15px;
}

.mosfet-padding {
  padding: 32px 0;
}

.light-img {
  width: 28px;
}

.heading {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  padding: 5px;
}

.img-center {
  justify-content: center;
  text-align: center;
}

.device-list {
  width: 100%;
  text-align: center;
  margin: 5px;
  padding: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


//pre loader sexy wala
.loadingContainer {
  position: relative;
  margin: 100px auto 0 auto;
  padding-top: 160px;
  width: 110px;
}

.loadingContainer div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: black;
  float: left;
  margin: 0 3px;
  background: rgb(15, 133, 51);
}

.loadingContainer .ball1 {
  z-index: 1;
  -moz-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation: bounce 0.75s infinite ease-in-out;
}

.loadingContainer .ball2 {
  -moz-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation-delay: 0.125s;
  animation-delay: 0.125s;
}

.loadingContainer .ball3 {
  -moz-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.loadingContainer .ball4 {
  -moz-animation: bounce 0.75s infinite ease-in-out;
  -webkit-animation: bounce .75s infinite ease-in-out;
  -webkit-animation-delay: 0.375s;
  animation-delay: 0.375s;
}

@-moz-keyframes bounce {

  0%,
  15% {
    -moz-transform: translate(0, 0);
  }

  50% {
    -moz-transform: translate(0, -30px);
    background: rgba(10, 242, 91, 1);
  }

  85%,
  100% {
    -moz-transform: translate(0, 0);
  }

  ;
}

@-webkit-keyframes bounce {

  0%,
  20% {
    -webkit-transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, -30px);
    background: rgba(10, 242, 91, 1);
  }

  80%,
  100% {
    -webkit-transform: translate(0, 0);
  }

  ;
}

.main-margin {
  margin-left: 1rem;
}
.adt-input {
  border: solid #0faef2;
  border-radius: 1rem;
}

.adt-blue-color {
  color: #0faef2
}
div.test-report-container h6{
text-align: center;
  color: #2196F3;
}

.test-report-container{
  background-color: #f1f5f7;
  h5{
color: #2196F3;
text-align: center;
padding: 5px;
font-weight: 900;
text-transform: uppercase;
  }
  span{
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: bold;
    margin: 15px 0;
    text-transform: uppercase;
    font-size: 10px;
  // float:right;
  }
}
.test-report-main-row{
  padding: 15px;
}

.test-report-row{
  // border: 1px solid black;
  padding: 5px;
  background-color: #a1eaf77e;
  margin: 0 5px 15px 5px;
  border-radius: 15px;
  // text-align: center;
}