.pin-state-red{
    height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}
.pin-state-green{
    height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin: auto;
}
.pin-div{
    padding: 15px;
}
.parallel-card-green{
    padding: 1rem;
    text-align: center;
    background-color: green;
}
.parallel-card-red{
    padding: 1rem;
    text-align: center;
    background-color: rgb(81, 40, 40);
}
.card-head{
  padding: 0 5px;
  font-size: 12px;
}
.card-foot{
  font-size: 14px;
}
.battery-container {
  width: 10rem;
  height: 20rem;
  margin: auto;
  background-color: #111;
  border: 1px solid slategrey;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.battery-level {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #037ff3, #2702f5);
  border-radius: 10px;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.3);
}

.battery-capacity {
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.battery-level:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to top, #0f0, #0a0);
  border-radius: 10px;
  transform-origin: bottom;
  animation: chargeAnimation 2s infinite linear;
  z-index: -1;
  opacity: 0.5;
  box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.3);
}

@keyframes chargeAnimation {
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}